import { buttonVariants } from "@/components/ui/button";
import {
	DialogHeader,
	DialogContent,
	DialogTitle,
	DialogDescription,
	DialogFooter,
	DialogClose,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { msg, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { ErrorMessage } from "src/components/ErrorMessage";
import { roomRoute } from "src/routes/currentRoomRoute";
import { useCreateRoom } from "src/state/react-query/useRoomData";
import { twMerge } from "tailwind-merge";

type CreateRoomDialogProps = {
	userId: string;
};

const minimumRoomNameLength = 3;
const maximumRoomNameLength = 100;
export const CreateRoomDialog = ({
	userId,
}: CreateRoomDialogProps): JSX.Element => {
	const { _ } = useLingui();
	const [roomName, setRoomName] = useState("");
	const [roomNameError, setRoomNameError] = useState<string | null>(null);
	const createRoomQuery = useCreateRoom({ userId });

	const navigate = useNavigate();

	async function createRoom() {
		const trimmedRoomName = roomName.trim();
		if (trimmedRoomName.length < minimumRoomNameLength) {
			return setRoomNameError(
				t`The roomName should be a minimum of ${minimumRoomNameLength} characters long!`,
			);
		}
		if (trimmedRoomName.length > maximumRoomNameLength) {
			return setRoomNameError(
				t`The roomName can be a maximum of ${maximumRoomNameLength} characters long!`,
			);
		}
		try {
			const roomNumber = await createRoomQuery.mutateAsync({
				roomName: trimmedRoomName,
			});
			navigate({ to: roomRoute.to, params: { roomNumber } });
		} catch (e) {
			console.error(e);
			return setRoomNameError(t`Could not create a room.`);
		}
	}

	const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			createRoom();
		}
	};

	return (
		<div className="w-full flex flex-col">
			{roomNameError && <ErrorMessage errorMessage={roomNameError} />}
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-2">
						<Trans>Add a new room!</Trans>
					</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					<div className="flex flex-col w-full">
						<Input
							className={
								roomNameError ? "ring-red-600 ring" : "border-primary "
							}
							placeholder={_(msg`Enter the room number...`)}
							type="text"
							value={roomName}
							maxLength={maximumRoomNameLength}
							onChange={(e) => {
								setRoomNameError(null);
								return setRoomName(e.target.value);
							}}
							onKeyDown={handleEnter}
						/>
						<p
							className={twMerge(
								"text-xs mt-1 w-fit ml-auto",
								roomNameError ? "  text-red-500" : "text-gray-200",
							)}
						>
							{roomName.length}/{maximumRoomNameLength}
						</p>
					</div>
				</DialogDescription>
				<DialogFooter>
					<DialogClose
						className={`${cn(buttonVariants())} mx-auto`}
						onClick={createRoom}
					>
						<Trans>Create room</Trans>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</div>
	);
};
