import {
	useIsAnonymousUser,
	useIsLoggedIn,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import {
	CardFooter,
	CardHeader,
	CardTitle,
	WhiteCard,
} from "@/components/ui/card";
import { CiCirclePlus } from "react-icons/ci";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { CreateRoomDialog } from "./CreateRoomDialog";
import { RoomsTable } from "./RoomsTable";
import { SignInToCreateRoom } from "./SignInToCreateRoom";
import { UserSettings } from "src/components/TopBar/UserSettings";
import { Alert } from "@/components/ui/alert";
import { Trans } from "@lingui/macro";

export function RoomsList(): JSX.Element {
	const userInfo = useUserInfoQuery();
	const { data: isLoggedIn } = useIsLoggedIn();
	const isAnonUser = useIsAnonymousUser();
	if (userInfo.isError) return <p>Error!</p>;
	if (userInfo.isPending) return <p>Loading...</p>;
	const userId = userInfo.data.user?.id;
	if (isLoggedIn && userId) {
		return (
			<WhiteCard>
				<CardHeader className="p-6">
					<CardTitle className="text-primary">
						<Trans>Rooms</Trans>
					</CardTitle>
				</CardHeader>
				<RoomsTable userId={userId} />
				<CardFooter className="flex flex-col items-center p-6 pt-0">
					{userId && isAnonUser ? (
						<Dialog>
							<DialogTrigger>
								<Alert className="hover:bg-slate-200 cursor-pointer text-center mt-2">
									<Trans>Add your email to create rooms!</Trans>
								</Alert>
							</DialogTrigger>
							<UserSettings
								userId={userId}
								currentEmailUser={userInfo.data.user?.email ?? ""}
								currentFullName={""}
							/>
						</Dialog>
					) : (
						<Dialog>
							<DialogTrigger>
								<CiCirclePlus className="text-4xl mt-3 cursor-pointer text-secondary" />
							</DialogTrigger>
							<CreateRoomDialog userId={userId} />
						</Dialog>
					)}
				</CardFooter>
			</WhiteCard>
		);
	}
	return <SignInToCreateRoom />;
}
