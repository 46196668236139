import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { roomRoute } from "./routes/currentRoomRoute";
import { indexRoute, rootRoute } from "./routes/rootRoute";
import { signInRoute } from "./routes/signInRoute";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "./state/react-query/client";
import { TooltipProvider } from "@/components/ui/tooltip";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { messages as enMessages } from "./locales/en";
import { messages as esMessages } from "./locales/es";
import * as Sentry from "@sentry/react";
import { enableMapSet } from "immer";
import { determineUserLocale, switchLocale } from "./i18n";

enableMapSet();
// Create the route tree using your routes
const routeTree = rootRoute.addChildren([indexRoute, roomRoute, signInRoute]);

// Create the router using your route tree
const router = createRouter({
	routeTree,
});

Sentry.init({
	dsn: "https://0aa51fa6e55437b72fa8dd1de74b4ecb@o4507764444561408.ingest.de.sentry.io/4507764468351056",
	integrations: [
		Sentry.tanstackRouterBrowserTracingIntegration(router),
		Sentry.replayIntegration(),
		Sentry.captureConsoleIntegration({
			levels: ["warn", "error", "assert"],
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ["localhost"],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	environment: import.meta.env.MODE,
});

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

i18n.load({
	en: enMessages,
	es: esMessages,
});
switchLocale(determineUserLocale());

// biome-ignore lint/style/noNonNullAssertion: app always needs to be present
ReactDOM.createRoot(document.getElementById("root")!).render(
	<StrictMode>
		<I18nProvider i18n={i18n}>
			<QueryClientProvider client={queryClient}>
				<TooltipProvider>
					<RouterProvider router={router} />
					<ReactQueryDevtools position="bottom" initialIsOpen={false} />
				</TooltipProvider>
			</QueryClientProvider>
		</I18nProvider>
	</StrictMode>,
);
