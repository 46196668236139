import { Button } from "@/components/ui/button";
import { type Dispatch, useState } from "react";
import { usePostReply } from "src/state/react-query/useQuestions";
import { useAddFullName } from "src/state/react-query/useUserInfoQuery";
import { TEMP_TIMING_KEY } from "src/state/react-query/react-query-keys";
import { NameInputField } from "src/components/NameInputField";
import { IoSend } from "react-icons/io5";

type NameInputQuestionProps = {
	userId: string;
	roomId: string;
	reply: string;
	parentId: string;
	setReply: Dispatch<React.SetStateAction<string>>;
};

export const NameInputReply = ({
	userId,
	roomId,
	reply,
	parentId,
	setReply,
}: NameInputQuestionProps): JSX.Element => {
	const [fullName, setFullName] = useState("");
	const addFullName = useAddFullName({ userId });
	const postReply = usePostReply();
	const hasQuestion = reply !== "";

	async function sendReply() {
		if (fullName) {
			addFullName.mutate({ fullName, userId });
			postReply.mutate({
				reply,
				parent_id: parentId,
				roomId,
				postAnonymous: false,
			});
			setReply("");
			console.time(TEMP_TIMING_KEY);
			return;
		}
		return alert("fullname is not defined");
	}

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
		event,
	) => {
		if (event.key === "Enter") {
			sendReply();
		}
	};

	return (
		<div className="flex w-full justify-start mt-3">
			<NameInputField
				className="mb-3 w-full"
				userId={userId}
				handleKeyDown={handleKeyDown}
				setFullName={setFullName}
				fullName={fullName}
			/>
			<Button
				disabled={!hasQuestion && !fullName}
				onClick={sendReply}
				className="self-end ml-2 mb-3"
				size="icon"
			>
				<IoSend />
			</Button>
		</div>
	);
};
