import { Button } from "@/components/ui/button";
import { ColorFilledCard } from "@/components/ui/card";
import { useState } from "react";
import { TEMP_TIMING_KEY } from "src/state/react-query/react-query-keys";
// import { Switch } from "@/components/ui/switch";
// import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { usePostQuestion } from "src/state/react-query/useQuestions";
// import { NameInputQuestion } from "../NameInputQuestion";
// import {
// 	// useFullNameParticipant,
// 	useUserInfoQuery,
// } from "src/state/react-query/useUserInfoQuery";
import { IoSend } from "react-icons/io5";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export function CreateQuestion({ roomId }: { roomId: string }) {
	const { _ } = useLingui();
	// const userInfo = useUserInfoQuery();
	// const userId = userInfo.data?.user?.id;
	// const { data } = useFullNameParticipant({
	// 	userId,
	// });
	const [question, setQuestion] = useState("");
	// const [postAnonymous, setPostAnonymous] = useState(false);
	// TODO disable Post button with postWithName && !fullName
	// const postWithName = !postAnonymous;
	const postQuestion = usePostQuestion();
	const hasQuestion = question !== "";
	// const hasName = data?.fullName && data?.fullName !== "Anonymous";

	async function handlePostQuestion() {
		postQuestion.mutate({
			question,
			roomId,
			postAnonymous: true,
		});
		setQuestion("");
		console.time(TEMP_TIMING_KEY);
	}

	return (
		<div className="mx-1">
			<ColorFilledCard className="relative mt-6">
				<div className="flex flex-wrap-reverse">
					<p className="text-white mt-4 mb-2 ml-5 font-bold">
						<Trans>Post your question here:</Trans>
					</p>
					{/* <div className="flex items-center space-x-2 self-end ml-auto mr-3 mt-3">
						<Label className={`text-white ${postAnonymous ? "font-bold" : ""}`}>
							{postAnonymous ? (
								<Trans>Anonymous</Trans>
							) : (
								<Trans>Not anonymous</Trans>
							)}
						</Label>
						<Switch
							checked={postAnonymous}
							onClick={() => setPostAnonymous((previous) => !previous)}
						/>
					</div> */}
				</div>
				<Textarea
					value={question}
					onChange={(e) => setQuestion(e.target.value)}
					placeholder={_(msg`Enter your question...`)}
					className={"mb-3 w-11/12 mx-auto"}
				/>

				{/* {!postAnonymous && !userId && (
					<p>
						<Trans>
							There is no user id. This question can only be posted anonymously.
						</Trans>
					</p>
				)} */}
				{/* {!postAnonymous && userInfo.isSuccess && userId && !hasName ? (
					<NameInputQuestion
						userId={userId}
						roomId={roomId}
						question={question}
						setQuestion={setQuestion}
					/>
				) : ( */}
				<div className="flex justify-end mr-5 mb-5">
					<Button disabled={!hasQuestion} onClick={handlePostQuestion}>
						<p className="mr-2">
							<Trans>Post</Trans>
						</p>
						<IoSend />
					</Button>
				</div>
				{/* )} */}
			</ColorFilledCard>
		</div>
	);
}
