import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { DeletePromptMessage } from "./DeletePromptQuestion";
import { useState, type Dispatch } from "react";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { Trans } from "@lingui/macro";
import type { Message } from "types/bindings/Message";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SlOptions } from "react-icons/sl";
import { IoCheckmark } from "react-icons/io5";
import { useUpdateAnswered } from "src/state/react-query/useUpdateAnswered";

type MessageOptionsMenuProps = Message & {
	setShowInput: Dispatch<React.SetStateAction<boolean>>;
	isCreator: boolean;
	isMessageAuthor: boolean;
};

export const MessageOptionsMenu = ({
	setShowInput,
	isCreator,
	isMessageAuthor,
	...message
}: MessageOptionsMenuProps): JSX.Element => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const updateAnswered = useUpdateAnswered({
		questionId: message.id,
		roomId: message.room_id,
	});
	const handleAnsweredFlag = async () => {
		await updateAnswered.mutateAsync({
			answered_at: message.answered_at === null ? "now" : null,
		});
	};

	return (
		<>
			<Dialog>
				<DropdownMenu open={menuIsOpen} onOpenChange={setMenuIsOpen}>
					<DropdownMenuTrigger
						className={`${cn(buttonVariants({ size: "icon", variant: menuIsOpen ? "outlinePressed" : "outline" }))} ml-1`}
					>
						<SlOptions className="text-xs" />
					</DropdownMenuTrigger>
					<DropdownMenuContent className="text-gray-500 ">
						{!message.parent_id && (
							<DropdownMenuItem
								className="hover:bg-gray-200 hover:text-gray-800 cursor-pointer"
								onClick={handleAnsweredFlag}
							>
								<IoCheckmark className="mr-1" />{" "}
								{message.answered_at === null ? (
									<Trans>Mark as answered</Trans>
								) : (
									<Trans>Mark as unanswered</Trans>
								)}
							</DropdownMenuItem>
						)}
						{(!isCreator || isMessageAuthor) && (
							<>
								<DialogTrigger>
									<DropdownMenuItem className="hover:bg-gray-200 hover:text-gray-800 cursor-pointer">
										<AiFillDelete className="mr-1" /> <Trans>Delete</Trans>
									</DropdownMenuItem>
								</DialogTrigger>
								<DropdownMenuItem
									className="hover:bg-gray-200 hover:text-gray-800 cursor-pointer"
									onClick={() => setShowInput((previous) => !previous)}
								>
									<AiFillEdit className="mr-1 " /> <Trans>Edit</Trans>
								</DropdownMenuItem>
							</>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
				<DeletePromptMessage {...message} />
			</Dialog>
		</>
	);
};
