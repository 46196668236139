import { QRCodeSVG } from "qrcode.react";
import { FieldWithClipBoardButton } from "./FieldWithClipboardButton";
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { addSpacingToRoomNumber } from "src/pages/homePage/components/GoToRoomCard";
import { Separator } from "@/components/ui/Separator";
import { Trans } from "@lingui/macro";

const baseUrlShortHand = new URL(
	window.location.origin === "https://app.questif.com"
		? "https://questif.com"
		: window.location.origin,
);

function getDisplayUrl({ roomNumber }: { roomNumber: string }) {
	const fullUrl = new URL(baseUrlShortHand);
	return `${fullUrl.hostname}/${addSpacingToRoomNumber(roomNumber)}`;
}

export function ShareRoomDialog({ roomNumber }: { roomNumber: string }) {
	const fullUrl = new URL(window.location.href);
	const shortendDisplayUrl = getDisplayUrl({ roomNumber });
	return (
		<DialogContent className=" h-[90%] max-h-xl  max-w-xl overflow-hidden">
			<DialogHeader>
				<DialogTitle className="text-2xl ">
					<Trans>Share room</Trans> {addSpacingToRoomNumber(roomNumber)}
				</DialogTitle>
			</DialogHeader>
			<div>
				<p className="text-center mb-4 font-bold">
					<Trans>Scan the following QR code:</Trans>
				</p>
				<div className="bg-white ">
					<QRCodeSVG
						className=" w-full h-full max-w-xl max-h-screen "
						value={fullUrl.toString()}
					/>
				</div>
				<div className="justify-center relative flex py-5 items-center max-w-md m-auto ">
					<Separator content="Or" />
				</div>
				<div className="text-center">
					<Trans>Copy and Share the following link:</Trans>
				</div>
				<FieldWithClipBoardButton
					displayText={shortendDisplayUrl.toString()}
					textToCopy={fullUrl.toString()}
					className="underline"
				/>
			</div>
		</DialogContent>
	);
}
