import { Textarea } from "@/components/ui/textarea";
import type React from "react";
import { useState } from "react";
import { usePostReply } from "src/state/react-query/useQuestions";
import {
	useFullNameParticipant,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import { Button } from "@/components/ui/button";
import { IoSend } from "react-icons/io5";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { NameInputReply } from "./NameInputReply";

type CreateReplyProps = {
	parentId: string;
	roomId: string;
};

export function CreateReply({ parentId, roomId }: CreateReplyProps) {
	const { _ } = useLingui();
	const userInfo = useUserInfoQuery();
	const userId = userInfo.data?.user?.id;
	const { data } = useFullNameParticipant({
		userId,
	});
	const [reply, setReply] = useState<string>("");
	const [postAnonymous, setPostAnonymous] = useState(false);
	const postReply = usePostReply();
	const hasReply = reply !== "";
	const hasName = data?.fullName !== null && data?.fullName !== "Anonymous";
	const showNameInput =
		!postAnonymous && userInfo.isSuccess && userId && !hasName;

	const sendReply = async () => {
		await postReply.mutateAsync({
			reply,
			parent_id: parentId,
			postAnonymous,
			roomId,
		});
		setReply("");
	};
	const handleEnter = async (
		event: React.KeyboardEvent<HTMLTextAreaElement>,
	) => {
		if (event.key === "Enter") {
			await sendReply();
		}
	};

	return (
		<div className="w-full ">
			<Textarea
				placeholder={_(msg`Your reply here...`)}
				onChange={(e) => setReply(e.target.value)}
				onKeyDown={(e) => handleEnter(e)}
				value={reply}
			/>
			<div className="grid grid-cols-5 w-full">
				<div
					className={`${!showNameInput && "float-left"} col-span-3 col-start-2 flex justify-self-end self-center mt-3 `}
				>
					<Label
						className={`text-gray-500 mt-1 mr-2 ${postAnonymous ? "font-bold" : ""}`}
					>
						{postAnonymous ? (
							<Trans>Anonymous</Trans>
						) : (
							<Trans>Not anonymous</Trans>
						)}
					</Label>
					<Switch
						checked={postAnonymous}
						onClick={() => setPostAnonymous((previous) => !previous)}
					/>
				</div>

				{showNameInput ? (
					<NameInputReply
						userId={userId}
						roomId={roomId}
						reply={reply}
						setReply={setReply}
						parentId={parentId}
					/>
				) : (
					<div className="justify-self-end self-center mt-3">
						<Button size="icon" disabled={!hasReply} onClick={sendReply}>
							<IoSend />
						</Button>
					</div>
				)}
			</div>
			{!postAnonymous && !userId && (
				<p>
					<Trans>
						There is no user id. This question can only be posted anonymously.
					</Trans>
				</p>
			)}
		</div>
	);
}
