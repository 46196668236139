import {
	type PostgrestSingleResponse,
	type User,
	createClient,
} from "@supabase/supabase-js";
import type { Database } from "../types/supabase";
import type { CamelCasedPropertiesDeep } from "type-fest";
import decamelizeKeys from "decamelize-keys";
export type voteType = Database["public"]["Enums"]["vote_type"];

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (supabaseAnonKey?.length < 5 || supabaseUrl?.length < 5) {
	throw Error("Missing environment variables");
}
export const client = createClient<Database>(supabaseUrl, supabaseAnonKey);
export async function deleteRoom({
	roomNumber,
}: { roomNumber: string }): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("room")
		.update({ archived_at: "now" })
		.eq("room_number", roomNumber)
		.throwOnError();
}

export async function updateRoom({
	newRoomName,
	roomNumber,
}: { newRoomName: string; roomNumber: string }): Promise<
	PostgrestSingleResponse<null>
> {
	return await client
		.from("room")
		.update({ name: newRoomName })
		.eq("room_number", roomNumber)
		.throwOnError();
}

export async function getUserInfo(): Promise<{ user: User | null }> {
	return (await client.auth.getUser()).data;
}
export async function isUserLoggedIn() {
	return (await client.auth.getSession()).data.session != null;
}

export type createRoomArgType = CamelCasedPropertiesDeep<
	Database["public"]["Functions"]["user_create_room"]["Args"]
>;
export async function createRoom(
	roomArguments: createRoomArgType,
): Promise<string> {
	const room_arguments = decamelizeKeys(roomArguments);
	const room_number = await client
		.rpc("user_create_room", room_arguments)
		.throwOnError();
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	return room_number.data!;
}

export type PostQuestionArgType = {
	question: string;
	roomId: string;
	postAnonymous: boolean;
};
export async function postQuestion({
	question,
	roomId,
	postAnonymous,
}: PostQuestionArgType): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question")
		.insert({
			content: question,
			room_id: roomId,
			anonymous: postAnonymous,
		})
		.throwOnError();
}

export type PostResponseArgType = {
	reply: string;
	roomId: string;
	postAnonymous: boolean;
	parent_id: string;
};
export async function postReply({
	reply,
	roomId,
	postAnonymous,
	parent_id,
}: PostResponseArgType): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question")
		.insert({
			content: reply,
			room_id: roomId,
			anonymous: postAnonymous,
			parent_id: parent_id,
		})
		.throwOnError();
}

export async function deleteMessage({
	questionId,
}: { questionId: string }): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question")
		.update({ archived_at: "now" })
		.eq("id", questionId)
		.throwOnError();
}

export async function updateMessage({
	newMessage,
	messageId,
}: { newMessage: string; messageId: string }): Promise<
	PostgrestSingleResponse<null>
> {
	return await client
		.from("question")
		.update({ content: newMessage })
		.eq("id", messageId)
		.throwOnError();
}

export async function UpdateVoteOnQuestion({
	userId,
	questionId,
	vote_kind,
}: {
	questionId: string;
	userId: string;
	vote_kind: voteType;
}): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question_vote")
		.update({ vote_kind })
		.eq("user_id", userId)
		.eq("question_id", questionId)
		.throwOnError();
}
export async function InsertVoteOnQuestion({
	userId,
	questionId,
	vote_kind,
}: {
	questionId: string;
	userId: string;
	vote_kind: voteType;
}): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question_vote")
		.insert({ question_id: questionId, vote_kind, user_id: userId })
		.eq("user_id", userId)
		.eq("question_id", questionId)
		.throwOnError();
}

export async function deleteVoteFromQuestion({
	userId,
	questionId,
}: {
	questionId: string;
	userId: string;
}): Promise<PostgrestSingleResponse<null>> {
	return await client
		.from("question_vote")
		.delete()
		.eq("user_id", userId)
		.eq("question_id", questionId)
		.throwOnError();
}

export async function addFullName({
	fullName,
	userId,
}: { fullName: string; userId: string }): Promise<
	PostgrestSingleResponse<null>
> {
	return await client
		.from("profile")
		.update({ full_name: fullName })
		.eq("user_id", userId)
		.throwOnError();
}

export async function updateAnswered({
	answered_at,
	questionId,
}: { answered_at: string | null; questionId: string }): Promise<
	PostgrestSingleResponse<null>
> {
	return await client
		.from("question")
		.update({ answered_at })
		.eq("id", questionId)
		.throwOnError();
}
