import {
	CardContent,
	CardFooter,
	CardHeader,
	WhiteCard,
} from "@/components/ui/card";
import {
	useFullNameParticipant,
	useUserIdQuery,
} from "src/state/react-query/useUserInfoQuery";
import { MessageOptionsMenu } from "./MessageOptionsMenu";
import { useEffect, useState } from "react";
import { useEditMessage } from "src/state/react-query/useQuestions";
import { Button, buttonVariants } from "@/components/ui/button";
import { ErrorMessage } from "src/components/ErrorMessage";
import { Textarea } from "@/components/ui/textarea";
import { shouldShowDebugInformation } from "src/settings";
import { CreateReply } from "../Replies/CreateReply";
import { DisplayReply } from "../Replies/DisplayReply";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Upvote } from "../Upvote";
import { cn } from "@/lib/utils";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import type { Message } from "types/bindings/Message";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { getRoleOfUser } from "src/state/react-query/useRoomsOfUser";
import { useDayjs } from "src/utils/customDayjs";
import { BsReply } from "react-icons/bs";

type QuestionProps = {
	message: Message;
	messages: Message[];
};

export function Question({ message, messages }: QuestionProps) {
	const {
		content,
		created_at: createdAt,
		anonymous,
		user_id: userId,
		id: questionId,
	} = message;
	const { _ } = useLingui();
	const repliesToCurrentQuestion = messages.filter(
		(r) => r.parent_id === questionId,
	);
	const [parent] = useAutoAnimate({
		duration: 250,
		easing: "ease-in-out",
		disrespectUserMotionPreference: false,
	});
	const [showInput, setShowInput] = useState(false);
	const [newQuestion, setNewQuestion] = useState<string | undefined>(undefined);
	const [collapsibleIsOpen, setCollapsibleIsOpen] = useState(false);
	const [showCreateReply, setShowCreateReply] = useState(false);
	const editQuestion = useEditMessage();
	const { data: currentUserId, isError: isErrorUserId } = useUserIdQuery();
	const { data: name, isError: isErrorFullName } = useFullNameParticipant({
		userId,
	});
	const { data } = getRoleOfUser({
		userId: currentUserId,
		roomId: message.room_id,
	});
	const isCreator = data?.role === "creator";
	const dayjs = useDayjs((state) => state.dayjs);

	if (isErrorFullName || isErrorUserId)
		return (
			<ErrorMessage
				errorMessage={_(
					msg`An error has ocurred when loading the name of this contributor.`,
				)}
				className="w-1/4 mx-auto"
			/>
		);
	const getNameParticipant = () => {
		if (anonymous) return <Trans>Anonymous</Trans>;
		if (!name) return "...";
		return name.fullName;
	};

	const handleEnter: React.KeyboardEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (e) => {
		if (e.key === "Enter") {
			handleQuestionEdit();
			setShowInput(false);
		}
	};

	const handleQuestionEdit = () => {
		if (newQuestion)
			editQuestion.mutate({ newMessage: newQuestion, messageId: questionId });
		setShowInput(false);
	};

	useEffect(() => {
		setShowCreateReply(collapsibleIsOpen);
	}, [collapsibleIsOpen]);

	return (
		<div className="mx-1">
			<WhiteCard>
				{shouldShowDebugInformation ? (
					<div className="text-xs">questionId: {message.id}</div>
				) : null}

				<CardHeader>
					{message.answered_at && (
						<div className="flex justify-end">
							<FaCircleCheck className="text-primary text-xl" />
						</div>
					)}
					<div className="flex justify-end">
						<div className="text-xs text-gray-500">
							<Trans>Posted</Trans> {dayjs.utc(createdAt).fromNow()}{" "}
							<Trans>by</Trans> {getNameParticipant()}
						</div>
					</div>
					{showInput ? (
						<>
							<Textarea
								className="text-black mt-1"
								onChange={(e) => setNewQuestion(e.target.value)}
								defaultValue={content}
								onKeyDown={(e) => handleEnter(e)}
							/>
							<div dir="rtl" className="flex">
								<Button
									onClick={() => setShowInput(false)}
									className="flex ml-2"
									size="icon"
									variant="destructive"
								>
									<MdCancel className=" text-xl" />
								</Button>
								<Button
									onClick={handleQuestionEdit}
									size="icon"
									className="flex bg-primary hover:bg-primary-dark"
								>
									<FaCircleCheck className="text-xl" />
								</Button>
							</div>
						</>
					) : (
						<div className="text-lg">{content}</div>
					)}
				</CardHeader>
				<CardContent>
					<Collapsible
						className="text-gray-500 text-lg mt-1 w-full"
						open={collapsibleIsOpen}
						onOpenChange={setCollapsibleIsOpen}
					>
						<div className="flex">
							<div className="float-left mr-1">
								<Upvote
									messageId={message.id}
									upvotes={message.upvotes}
									upvoted_by_user={message.upvoted_by_user}
								/>
								{(currentUserId === userId || isCreator) && (
									<MessageOptionsMenu
										setShowInput={setShowInput}
										isCreator={isCreator}
										isMessageAuthor={currentUserId === userId}
										{...message}
									/>
								)}
							</div>

							<Button
								variant={showCreateReply ? "outlinePressed" : "outline"}
								className="md:text-sm text-xs mr-1"
								onClick={() => setShowCreateReply((previous) => !previous)}
							>
								<BsReply />
							</Button>

							{repliesToCurrentQuestion &&
								repliesToCurrentQuestion.length > 0 && (
									<CollapsibleTrigger
										className={`${cn(buttonVariants({ variant: collapsibleIsOpen ? "defaultPressed" : "default" }))} mr-1`}
									>
										<p className="mr-1 md:text-sm text-xs">
											{repliesToCurrentQuestion.length} <Trans>replies</Trans>
										</p>

										{collapsibleIsOpen ? (
											<IoIosArrowUp className="mt-1" />
										) : (
											<IoIosArrowDown className="mt-1" />
										)}
									</CollapsibleTrigger>
								)}
						</div>

						<CollapsibleContent className="mt-1">
							<li ref={parent} className="list-none text-start">
								{repliesToCurrentQuestion?.map((reply) => (
									<DisplayReply key={reply.id} reply={reply} />
								))}
							</li>
						</CollapsibleContent>
					</Collapsible>
				</CardContent>
				{showCreateReply && (
					<CardFooter>
						<CreateReply parentId={questionId} roomId={message.room_id} />
					</CardFooter>
				)}
			</WhiteCard>
		</div>
	);
}
